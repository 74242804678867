import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TopBar from '../components/top-bar/top-bar';
import {
  Button, Container, Section, Wrapper, Image, Counter, SubTitle,
} from '../styles/nosotros.elements';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Nosotros"}) {
        title
        images {
          gatsbyImageData
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  return (
    <Layout>
      <main>
        <TopBar title="Nosotros" imageName="nosotros.jpg" />
        <Section>
          <Wrapper>
            <Container width="45%">
              <SubTitle>QUIENES SOMOS ?</SubTitle>

              <p>
                Somos una empresa con mas de <strong>19</strong> años de experiencias, dedicada a la prestación de servicios y suministro de material para infraestructuras de sistemas en redes de cableado estructurado y redes eléctricas.
              </p>
              <p>
                Pensando en las necesidades de nuestros clientes, hemos implementado un amplio portafolio, servicios integrales de obra civil, ingeniería mecatrónica, suministro e instalación de mobiliaria, entre otros.
              </p>
              <Button to="/contactenos">Contactenos</Button>
              <Button to="/servicios">Servicios</Button>

            </Container>
            <Container width="45%">
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Ingenieros y administradores leyendo planos" max_width="none" padding="true" objectFit="contain" />
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container width="45%">
              <Image image={getImage(images[1]) as IGatsbyImageData} alt="Sala de juntas" max_width="none" padding="true" />
            </Container>
            <Container width="50%" className="space">
              <Counter>
                <div>
                  <p>Más de</p>
                  <p>19</p>
                  <p>Años de experiencia</p>
                </div>
              </Counter>
              <Counter>
                <div>
                  <p>Más de</p>
                  <p>1000</p>
                  <p>Proyectos a nivel nacional</p>
                </div>
              </Counter>
              <Counter>
                <div>
                  <p>Más de</p>
                  <p>50</p>
                  <p>Clientes satisfechos</p>
                </div>
              </Counter>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container width="45%" className="fit">
              <Image image={getImage(images[2]) as IGatsbyImageData} alt="Canales de comunicacion" max_width="none" padding="true" />
              <SubTitle>MISIÓN</SubTitle>
              <p>Electriredes es una empresa dedicada a la instalación y mantenimiento de redes de cableado estructurado y redes eléctricas, implementando innovación y desarrollo constante de acuerdo a las tendencias globales de la tecnología, ofreciendo así un amplio portafolio de servicios.</p>
            </Container>
            <Container width="45%">
              <Image image={getImage(images[3]) as IGatsbyImageData} alt="Computadora con estadisticas de proyectos" max_width="none" padding="true" />
              <SubTitle>VISIÓN</SubTitle>
              <p>En el año 2031 Electriredes Lussan SAS. será una empresa integradora de servicios múltiples, reconocida a nivel nacional por su excelencia e innovación en el desarrollo tecnológico, con énfasis en altos estándares de calidad y cumplimiento orientado al desarrollo de nuestro recurso humano generando conciencia en la preservación del medio ambiente.</p>
              <p>Nos destacaremos por:</p>
              <ul>
                <li>Excelentes relaciones comerciales</li>
                <li>Crecimiento exponencial</li>
                <li>Mejora continua en atención al cliente</li>
              </ul>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container width="45%">
              <Image image={getImage(images[4]) as IGatsbyImageData} alt="Explicacion en sala de juntas" />
              <Image image={getImage(images[5]) as IGatsbyImageData} alt="Administracion lista de chequeo" />
            </Container>
            <Container width="45%">
              <SubTitle>POLITICAS DE CALIDAD</SubTitle>
              <p>Estamos comprometidos a desarrollar el Sistema de Gestión de Calidad (S.G.C) en aras de dirigir nuestra compañía hacia el mejoramiento constante en la prestación de nuestros servicios y productos ofrecidos.</p>
              <p>Dentro de nuestro Sistema de Gestión de Calidad hemos establecido las siguientes políticas:</p>
              <p>1. Realizar el diseño, suministro e instalación de sistemas de cableado estructurado, sistemas de seguridad e instalaciones eléctricas, que cumplan con los requisitos establecidos por los estándares nacionales e internacionales.</p>
              <p>2. Garantizar la satisfacción completa de nuestros clientes, por medio de la ejecución efectiva de los trabajos por personal altamente capacitado y comprometido, de esa manera minimizar los posibles riesgos.</p>
              <p>3. Utilizando la tecnología avanzada y la mejora continua de las habildades de nuestro personal constantemente de acuerdo a las normas establecidas en los estándares de calidad en productos y servicios.</p>
              <p>4. Mejora continua e imparable en todos los procesos de nuestra empresa.</p>
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default AboutPage;

export const Head: HeadFC = () => <Seo title="Nosotros" />;
