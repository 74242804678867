import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 3rem 3rem;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  color: white;
  /* background-image: linear-gradient(156deg,#003977 0%,#e02b20 100%);

  &:nth-child(even) {
    background-image: linear-gradient(156deg,#e02b20 0%,#003977 100%);
  } */
  box-shadow: 0 -5px 10px #707070;
  &:nth-child(even) {
    background-color: #E9ECEF;
  }
  color: #000;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1300px;
  margin: auto;
`;

interface Conrainerprops {
  width?: string
}
export const Container = styled.div<Conrainerprops>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;

  & p {
    padding: 15px 10px;
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.7em;
  }

  &.border {
    border-top: 5px solid #F4FC54;
    &:nth-child(odd) {
    border-top: 5px solid #013977;
    }
  }

  & ul {
    position: relative;
    width: 100%;
    left: 35px;
    margin-bottom: 15px;

  }

  & li {
    margin-bottom: 7px;
    font-size: 1rem;
  }

  @media screen and (min-width: 788px) {
    width: ${({ width }) => (width ?? 'auto')};
    &.space {
      justify-content: space-evenly ;
    }

    &.fit {
      height: fit-content;
    }
  }
`;

export const Button = styled(Link)`
  align-items: center;
  text-decoration: none;
  padding: 0.3em 1em;
  font-size: 1.3rem;
  color: var(--main-blue);
  border: 2px solid var(--main-blue);;
  border-radius: 3px;
  transition: all .2s;
  margin-right: 15px;
  
  &::after {
    font-size: 32px;
    line-height: 1em;
    content: ">";
    opacity: 0;
    position: absolute;
    margin-left: -1em;
    -webkit-transition: all .2s;
    transition: all .2s;
    text-transform: none;
    -webkit-font-feature-settings: "kern" off;
    font-feature-settings: "kern" off;
    font-variant: none;
    font-style: normal;
    font-weight: 400;
    text-shadow: none;
  }

  &:hover {
    border-color: var(--main-red);;
    padding: 0.3em 2em 0.3em 0.7em;
    border-radius: 0px;
    background-color: hsla(0,0%,100%,.2);
    &::after {
    opacity: 1;
    margin-left: .7%;
    margin-top: -.2%;
    }
  }
`;

interface Imageprops {
  max_width?: string
  padding?: string

}
export const Image = styled(GatsbyImage) <Imageprops>`
  width: 100%;
  max-width: 475px;
  margin: 0 auto 20px;
  @media screen and (min-width: 788px) {
    max-width: ${({ max_width }) => (max_width ?? '475px')};
    padding: ${({ padding }) => (padding ? '0 25px' : 0)};
    
  }
`;

export const Counter = styled.div`
  
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  & div {
    max-width: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    border-radius: 30px;
    padding: 0 10px;
  }

  & p {
    text-align: center;
    max-width: max-content;
    padding: 0;
    font-size: 2rem;
    line-height: 3.5rem;
  }

  & p:nth-child(2) {
    font-size: 3.5rem;
  }

  @media screen and (min-width: 788px) {
    margin-bottom: 0;
    width: auto;
    align-items: center;

    & div {
      border: none;
    }
    
    & p {
      font-size: 1.4vw;
      line-height: normal;
    }

    & p:nth-child(2) {
      font-size: 3rem;
    }

    &:nth-child(2) {
      min-width: 200px;
    }
  }
`;

export const SubTitle = styled.h2`
  width: 100%;
  text-align: center;
`;
